import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import TableTrader from "../../components/TraderManagement/TableTrader";
import { TraderFilter } from "../../components/TraderManagement/TraderFilter";
import Nav from "../../components/TraderManagement/Nav";
import { getPaginateTradersByIB } from "../../api/trader";
import { DEFAULT_LIMIT } from "../../constant/pagination";

function DashboardTraderManagement() {
  const [traders, setTraders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total_page, setTotalPage] = useState(0);
  const [filters, setFilters] = useState({
    email: "",
    id: "",
    phone: "",
    marketing_name: "",
  });
  
  const fetchTraders = async () => {
    setLoading(true);
    try {
      const offset = (page - 1) * DEFAULT_LIMIT;
      const response = await getPaginateTradersByIB(
        DEFAULT_LIMIT,
        offset,
        filters.email,
        filters.id,
        filters.marketing_name
      );
      setTraders(response.data || []);
      setTotalPage(response.total_page || 0);
    } catch (error) {
      console.error("Error fetching traders:", error);
      setTraders([]);
      setTotalPage(0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTraders();
  }, [page, filters]);

  const handleSearch = (searchFilters) => {
    setFilters(searchFilters);
    setPage(1);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Trader - Zexter Admin Dashboard</title>
      </Helmet>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Nav />
            </Col>
            <Col lg={12}>
              <TraderFilter onSearch={handleSearch} />
            </Col>
            <Col lg={12}>
              <TableTrader
                setPage={setPage}
                page={page}
                total_page={total_page}
                traders={traders}
                loading={loading}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default DashboardTraderManagement;
