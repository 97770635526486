//TableTrader.jsx
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody } from "reactstrap";
import commanumber from "comma-number";
import { toast, ToastContainer } from "react-toastify";
import Pagination from "../Common/Pagination";
import Moment from "react-moment";

function TableTrader({ page, setPage, total_page, traders, loading }) {
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!traders || traders.length === 0) {
    return (
      <Card>
        <CardBody>
          <div className="text-center">
            <h5>No data found</h5>
          </div>
        </CardBody>
      </Card>
    );
  }

  async function copyClipboard(text) {
    try {
      await navigator.clipboard.writeText(text);
      toast("Copied !", {
        position: "top-center",
        hideProgressBar: true,
        className: "bg-success text-white",
      });
    } catch (error) {
      console.log(error);
    }
  }

  let render_traders = traders.map((item) => (
    <tr key={item.id}>
      <th scope="row">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            name="chk_child"
            value="option1"
          />
        </div>
      </th>
      <td className="customer_name">
        <i
          onClick={() => copyClipboard(item.id)}
          style={{ cursor: "pointer" }}
          className="ri-file-copy-line"
        ></i>
        <Link to={`/trader/${item.id}`} className="">
          {item.id}
        </Link>
      </td>
      <td className="email">
        <Link to={`/trader/${item.id}`} className="">
          {item.email}
        </Link>
      </td>
      <td className="phone">{item.phone}</td>
      <td className="date text-success">
        {commanumber(Number(item.cash_balance).toFixed(2))}
      </td>
      <td className="status">{item.status || "N/A"}</td>
      <td className="marketing_platform">{item.marketing_platform || "N/A"}</td>
      <td className="marketing_name">{item.marketing_name || "N/A"}</td>
      <td className="preferred_lang">{item.preferred_lang}</td>
      <td className="preferred_currency">{item.preferred_currency}</td>
      <td className="created_at">
        <Moment calendar date={item.created_at} />
      </td>
    </tr>
  ));

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title mb-0">Trader List</h4>
      </CardHeader>
      <ToastContainer autoClose={1000} />
      <CardBody>
        <div id="customerList">
          <div className="table-responsive table-card mb-1">
            <table
              className="table align-middle table-nowrap"
              id="customerTable"
            >
              <thead className="table-light">
                <tr>
                  <th scope="col" style={{ width: "50px" }}>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="checkAll"
                        value="option"
                      />
                    </div>
                  </th>
                  <th className="sort" data-sort="customer_name">
                    Trader ID
                  </th>
                  <th className="sort" data-sort="email">
                    Email
                  </th>
                  <th className="sort" data-sort="phone">
                    Phone
                  </th>
                  <th className="sort" data-sort="cash_balance">
                    Cash Balance
                  </th>
                  <th className="sort" data-sort="status">
                    Status
                  </th>
                  <th className="sort" data-sort="marketing_platform">
                    Marketing Platform
                  </th>
                  <th className="sort" data-sort="marketing_name">
                    Marketing Name
                  </th>
                  <th className="sort" data-sort="preferred_lang">
                    Preferred Lang
                  </th>
                  <th className="sort" data-sort="preferred_currency">
                    Preferred Currency
                  </th>
                  <th className="sort" data-sort="created_at">
                    Created At
                  </th>
                </tr>
              </thead>
              <tbody className="list form-check-all">{render_traders}</tbody>
            </table>
          </div>

          <div className="d-flex justify-content-between">
            <div className="flex-shrink-0 mt-1">
              <div className="text-muted">
                Showing <span className="fw-semibold">{page}</span> of{" "}
                <span className="fw-semibold">{total_page}</span> Results
              </div>
            </div>
            <div className="pagination-wrap hstack gap-2">
              <Pagination
                setPage={setPage}
                initial_page={page}
                total_page={total_page}
              />
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default TableTrader;
